<template>
  <clinicViewEditDetail></clinicViewEditDetail>
</template>

<script>

  import clinicViewEditDetail from '../../components/clinics/viewEditDetail.vue'

  export default {
    components: {
      clinicViewEditDetail
    },
    data:()=>({

    })
  }
</script>
